﻿export function AccordionRow() {
    document.querySelectorAll('.accordion-row .foldable button').forEach(item => {

        if (isOverflown(item.previousElementSibling) === false) {
            toggleClass(item.previousElementSibling, 'm-0');
            toggleClass(item.previousElementSibling, 'p-0');
            toggleClass(item, 'd-none');
        }

        item.addEventListener('click',
            function (e) {
                toggleClass(e.target, 'open');
                toggleClass(e.target.previousElementSibling, 'open');

                toggleAttribute(e.target, 'aria-label', 'Toon meer informatie', 'Toon minder informatie')
                toggleAttribute(e.target, 'aria-expanded', 'false', 'true')
                toggleAttribute(e.target.previousElementSibling, 'aria-hidden', 'true', 'false');

                return false;
            });
    });
}

function isOverflown(element) {
    return element.scrollHeight > element.clientHeight;
}

function toggleClass(element, className) {
    element.classList.toggle(className);
}

function toggleAttribute(element, attribute ,labelClosed, labelOpen) {

    if (element.getAttribute(attribute) === labelClosed) {
        element.setAttribute(attribute, labelOpen)
    } else {
        element.setAttribute(attribute, labelClosed)
    }
}