﻿//Service which accesses application wide settings which values comes from Umbraco
export let UmbracoSettingsService = (function() {
	// Singleton reference 
	var instance;

	function Init() {
		//The main script tag contains a data attribute which hold a list of options
		const mainScriptTag = document.getElementById("main-script");
		const settingsData = JSON.parse(mainScriptTag.getAttribute("data-global-settings"));
		this.settings = settingsData;
	}
 
	return function() {
		if ( !instance ) {
			instance = new Init();
		}
		return instance;
	};
})();