﻿export function ShowMobileFilters() {
    let showFiltersBtn = document.getElementsByClassName("mobile-filters-button");
    let filtersMobile = document.getElementsByClassName("checkbox-filters-mobile");

    if (showFiltersBtn != null && filtersMobile != null && filtersMobile.length > 0) {
        showFiltersBtn[0].addEventListener("click", function (e) {
            filtersMobile[0].style.display = "block";
            window.scrollTo(0, 0);
        });
    }
}

export function HideMobileFilters() {
    let closeBtn = document.getElementById("close-filters-btn")
    let filtersMobile = document.getElementsByClassName("checkbox-filters-mobile");

    if (closeBtn != null && filtersMobile != null && filtersMobile.length > 0) {
        closeBtn.addEventListener("click", function (e) {
            filtersMobile[0].style.display = "none";
        });
    }
}