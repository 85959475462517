﻿export function LocationsAccordion() {
	document.querySelectorAll('.checkbox-filter-foldable h3').forEach(item => {
		item.addEventListener('click',
			function(e) {
				e.target.classList.toggle('open');
				e.target.nextElementSibling.classList.toggle('open');
				return false;
			});
		item.addEventListener('keypress',
			function (e) {
				if (e.key === "Enter") {
					e.target.classList.toggle('open');
					e.target.nextElementSibling.classList.toggle('open');
					return false;
				}				
			});
	});
}
