﻿export let GdprManager = (function () {
	// Singleton reference 
	var instance;

	//The permission level options
	var permissionLevel = { "Basic": 1, "Complete": 3, "NotSet": 0 };
	//The name for the cookie that holds the unique visitor GDPR id
	var cookieNameId = "RivasGdprId";
	//The name for the cookie which holds the stored permission
	var cookieNamePermission = "RivasGdrpPermission";
	//Event for changed permission
	var permissionChanged = document.createEvent("CustomEvent");

	//Gets the value from a cookie by name
	var getCookieValue = function (name) {
		var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
		return !!pair ? pair[1] : null;
	};

	//Sets a cookie
	var setCookie = function (name, value, expires) {
		document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
	};

	//Generates a Guid
	//http://slavik.meltser.info/the-efficient-way-to-create-guid-uuid-in-javascript-with-explanation/
	function createGuid() {
		function p8(s) {
			var p = (Math.random().toString(16) + "000000000").substr(2, 8);
			return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
		}
		return p8() + p8(true) + p8(true) + p8();
	}

	//Sets the given permission in a cookie
	function setPermission(permissionLevel) {
		var currentDate = new Date();
		setCookie(cookieNamePermission,
			permissionLevel,
			new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDay()));

		//Create an event for changes to the permission
		permissionChanged.initCustomEvent("permissionChanged", true, false, { permissionLevel: permissionLevel });
		document.dispatchEvent(permissionChanged);
	};

	//Sets the cookie with a unique GDPR Id
	function setIdCookie() {
		var currentCookie = getCookieValue(cookieNameId);
		if (currentCookie === null) {
			var guid = createGuid();
			var currentDate = new Date();
			setCookie(cookieNameId,
				guid,
				new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDay()));
		}
	}

	//Gets the GDPR Id of the current visitor
	function getCurrentId() {
		return getCookieValue(cookieNameId);
	}

	//Gets the current permission of the current visitor
	function getCurrentPermission() {
		return parseInt(getCookieValue(cookieNamePermission));
	}

	//Initializes the manager
	function Initialize() {
		//Set cookies on the first visit
		setIdCookie();
		if (!getCurrentPermission())
			setPermission(permissionLevel.NotSet);

		this.getCurrentPermission = getCurrentPermission;
		this.setPermission = setPermission;
		this.permissionChanged = permissionChanged;
		this.permissionLevel = permissionLevel;
		this.getCurrentId = getCurrentId;
	}

	return function () {
		if (!instance) {
			instance = new Initialize();
		}
		return instance;
	};
})();

export let GdprScreenManager = (function () {
	let gdprManager = GdprManager();
	// Singleton reference 
	var instance;

	//If true, the screen will be closed automatically when the save button is pressed
	var autoCloseScreen = true;

	//If true, the screen will be deleted from the HTML when closing, otherwise it will only be hidden.
	var destroyScreenOnClose = true;

	//If true, the screen expects cookies to be added to other domains
	var setCookiesOnOtherDomains = false;

	//Loads the GDPR screen and displays it to the user
	function loadScreen(options) {
		if (gdprManager.getCurrentPermission() === gdprManager.permissionLevel.NotSet) {
			var container = document.getElementById("gdpr-screen-content");
			container.style.display = "flex";
		}

		//Add the Accept button
				var acceptButton = document.getElementById("cookie-accept-button");
				acceptButton.addEventListener("click", function () { handlePermissionChanged(3) });

				//Add the deny button
				var denyButton = document.getElementById("cookie-deny-button");
				denyButton.addEventListener("click", function() { handlePermissionChanged(1) });
	};

	//Handles the saving of a changed permission level
	function handlePermissionChanged(cookieLevel) {
		//Get the new and old permission values
		var newPermission = cookieLevel;
		var currentPermission = gdprManager.getCurrentPermission();


		//Only if the new permission is different from the old, set it! (causing event)
		if (newPermission !== currentPermission || setCookiesOnOtherDomains) {
			gdprManager.setPermission(newPermission);
		}

		//If auto close is enabled, close the screen
		if (autoCloseScreen && !setCookiesOnOtherDomains) {
			closeScreen();
		}
	};

	//Sets cookies on external sites
	function setExternalCookies(cookieData) {

		//If there is no cookie data, just close the window
		if (!cookieData) {
			closeScreen();
			return;
		}

		//Check if the GdprScreen is still present, if so we can display data on it, otherwise we work in silence
		var showOnScreen = gdprScreen;
		var content = document.getElementById("gdpr-screen-content");
		if (showOnScreen) {
			content.innerHTML = "";

			var title = document.createElement("h2");
			title.innerHTML = "Externe sites";
			var cookieText = document.createElement("p");
			cookieText.innerHTML = "De instellingen worden op de volgende sites geüpdate:";

			content.appendChild(title);
			content.appendChild(cookieText);
		}

		var cookieList = document.createElement("ul");
		//Loop through the cookies
		for (var i = 0; i < cookieData.length; i++) {

			//If the data for the cookie is not correct, skip it
			if (!cookieData[i].siteName || !cookieData[i].urlToCall) {
				continue;
			}

			var listItem = document.createElement("li");

			//Create a 'fake' image for loading cookies from an external domain
			var fakeImage = document.createElement("img");
			fakeImage.setAttribute("style", "display:none;");
			fakeImage.onload = function () {
				this.setAttribute("data-ready", true);
				this.parentElement.className += " ready";
			};
			fakeImage.onerror = function () {
				this.setAttribute("data-ready", true);
				this.parentElement.className += " error";
			};

			fakeImage.src = cookieData[i].urlToCall;

			//If we still have an active gdpr screen, add the fake images to it and display status texts,
			//otherwise append them to the body
			if (showOnScreen) {
				//content.appendChild(fakeImage);
				listItem.className = "cookie-item";
				listItem.innerHTML += cookieData[i].siteName;
				listItem.appendChild(fakeImage);
				cookieList.appendChild(listItem);

			} else {
				body.appendChild(fakeImage);
			}
		}

		if (showOnScreen) {
			content.appendChild(cookieList);
		}

		//If the gdpr screen is still open, close it
		var imageLoadInterval = setInterval(function () {
			var images = gdprScreen.getElementsByTagName("img");
			for (var index = 0; index < images.length; index++) {
				if (!images[index].getAttribute("data-ready")) {
					return;
				}

				clearInterval(imageLoadInterval);
				if (showOnScreen) {
					setTimeout(function () {
						gdprScreenManager.closeScreen();
					},
						500);
				}
			}
		}, 1000);
	};

	//Closes the Gdpr screen
	function closeScreen() {
		let gdprScreen = document.getElementById("gdpr-screen-content");
		if (!destroyScreenOnClose && !setCookiesOnOtherDomains) {
			gdprScreen.style.display = "none";
		} else {
			gdprScreen.parentNode.removeChild(gdprScreen);
		}
		location.reload();
	};

	function Initialize() {
		this.loadScreen = loadScreen;
		this.closeScreen = closeScreen;
		this.handlePermissionChanged = handlePermissionChanged;
		this.setExternalCookies = setExternalCookies;
	}
	return function () {
		if (!instance) {
			instance = new Initialize();
		}
		return instance;
	};


})();
