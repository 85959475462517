﻿export function Accessibility() {
	let maxStep = 2,
		minFontSize = 16,
		fontStepIncrease = 4,
		cookieName = 'RivasAccessibility',
		accessibilityData = { fontStep: 1 };
	var getCookie = function (name) {
		var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
		return !!pair ? pair[1] : null;
	};
	var setCookie = function (name, value, expires) {
		document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
	};

	var saveAccessibilityData = function () {
		let expires = new Date();
		expires.setMonth(expires.getMonth() + 6);
		setCookie(cookieName, JSON.stringify(accessibilityData), expires);
	}

	var loadAccessibilityData = function () {
		var data = getCookie(cookieName);
		if (data) {
			data = JSON.parse(data);
			accessibilityData = data;
		} else {
			throw new Error("Unable to load accessibility cookie.");
		}
	}

	var handleFontSize = function (fromEvent = true) {
		let html = document.querySelector('html');
		if (fromEvent) {
			accessibilityData.fontStep++;
			if (accessibilityData.fontStep > maxStep) {
				accessibilityData.fontStep = 1;
			}
		}
		html.style.fontSize = (minFontSize + (accessibilityData.fontStep - 1) * fontStepIncrease) + 'px';
		if (fromEvent) {
			saveAccessibilityData();
		}
	}
	document.addEventListener("DOMContentLoaded", function () {
		try {
			loadAccessibilityData();
			handleFontSize(false);
		} catch (err) {

		}
	});
	
	document.querySelector('html').setAttribute('style', 'font-size: 16px');
	document.querySelectorAll('.accessibility-enlarge').forEach(function (item) {
		item.addEventListener('click', handleFontSize);
	});
}