﻿import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

export function TinySlider() {

    window.addEventListener('load', function () {
        if (document.getElementsByClassName('tiny-slider-container').length > 0) {
            let slider = tns({
                container: ".tiny-slider-container",
                mode: 'gallery',
                animateIn: 'tns-fadeIn',
                animateOut: 'tns-fadeOut',
                speed: 1000,
                loop: true,
                nav: false,
                controls: false,
                controlsContainer: '#controls',
                prevButton: document.querySelector('.slider-previous').onclick = function () { slider.goTo('prev'); },
                nextButton: document.querySelector('.slider-next').onclick = function () { slider.goTo('next'); }
            });
        }
    });
}