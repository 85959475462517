﻿export function FooterAccordion() {
	document.querySelectorAll('.layout-footer .foldable h2').forEach(item => {
				item.addEventListener('click',
					function(e) {
						e.target.classList.toggle('open');
						e.target.nextElementSibling.classList.toggle('open');
						return false;
					});
			});
}
