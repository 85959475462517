﻿export function Collapse() {
	var mores = document.getElementsByClassName("module-related-pages-more");

	for (let i = 0; i < mores.length; i++) {
		mores[i].addEventListener("click",
			function () {
				const siblings = this.parentNode.children;
				for (let a = 3; a < siblings.length; a++) {
					if (siblings[a] !== this) {
						siblings[a].classList.add('show');
					}
				}
				this.hidden = true;
			});
	}
}