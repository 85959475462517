﻿import { UmbracoSettingsService } from "/scripts/services/UmbracoSettingsService.js"
const settingsManager = UmbracoSettingsService();

export function initializeGoogleServices() {
	//Initialize Google tag manager if applicable
	if (settingsManager.settings.isTagmanagerActive === true && settingsManager.settings.tagManagerContainerId !== "") {
		(function(w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({
				'gtm.start':
					new Date().getTime(),
				event: 'gtm.js'
			});
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src =
				'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', settingsManager.settings.tagManagerContainerId);
	}

	//Initialize Google analytics if applicable
	if (settingsManager.settings.isAnalyticsActive && settingsManager.settings.analyticsIds) {
		const analyticsIds = settingsManager.settings.analyticsIds;

		(function(i, s, o, g, r, a, m) {
			i['GoogleAnalyticsObject'] = r;
			i[r] = i[r] ||
				function() {
					(i[r].q = i[r].q || []).push(arguments)
				}, i[r].l = 1 * new Date();
			a = s.createElement(o), m = s.getElementsByTagName(o)[0];
			a.async = 1;
			a.src = g;
			m.parentNode.insertBefore(a, m)
		})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

		var analyticsLength = analyticsIds.length;
		for (var index = 0; index < analyticsLength; index++) {
			var trackerId = 'clientTracker' + index;
			ga('create', analyticsIds[index], 'auto', trackerId);
			ga(trackerId + '.send', 'pageview');
		}
	}

}

