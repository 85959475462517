﻿//Load Google services
import { initializeGoogleServices } from "/scripts/loaders/google-services.js"
initializeGoogleServices();

//Load mobile menu
import { initializeMenu } from "/scripts/loaders/main-menu-loader.js";
initializeMenu();

//Load lazy sizes/loading of images
import { initializeLazySizes } from "/scripts/loaders/lazy-sizes-loader.js";
initializeLazySizes();

//Load footer accordion
import { FooterAccordion } from "/scripts/modules/footer-accordion.js";
FooterAccordion();

//Load JsPdf
import { initializeJsPdf } from "/scripts/loaders/jspdf-loader.js";
initializeJsPdf();

//Load accordion row
import { AccordionRow } from "/scripts/modules/accordion-row.js";
AccordionRow();

import { Accessibility } from "/scripts/modules/accessibility.js"
Accessibility();

//Load Dropdown
import { DropDown } from "/scripts/modules/dropdown.js";
DropDown();

//Load collapse
import { Collapse } from "/scripts/modules/collapse.js";
Collapse();

//Initialize GDPR
import {initializeGdpr} from "/scripts/loaders/gdpr-loader.js"
initializeGdpr();

//Load footer accordion
import { LocationsAccordion } from "/scripts/modules/locations-accordion.js";
LocationsAccordion();

//load tiny slider
import { TinySlider } from "/scripts/modules/tinyslider.js";
TinySlider();

//load multi select
import { VacanciesFilter } from "/scripts/modules/vacancies-filter.js";
VacanciesFilter();

//load show mobile filters
import { ShowMobileFilters } from "/scripts/modules/mobile-filters.js";
ShowMobileFilters();

//load hide mobile filters
import { HideMobileFilters } from "/scripts/modules/mobile-filters.js";
HideMobileFilters();

//Load popup interaction script
import { Popup } from "/scripts/modules/popup.js"
Popup();