﻿import { jsPDF } from "jspdf";
import { html2Canvas } from "html2canvas";

// <summary>
// jspdf uses html2canvas. This is why we need to open all the accordions before it's printed. Otherwise all the text in closed accordions will be hidden
// We try to minimize the user experience by closing the code-opened accordions at the end. This is done with a setTimeOut to ensure the printed page has all opened accordions
// </summary>
// <returns></returns>
export function initializeJsPdf() {

	const button = document.getElementById('generatePdfLink');

	if (button) {
		button.onclick = function () {

			const pdfDocument = document.getElementById('pdfDocument');
			const documentName = pdfDocument.dataset.pdfname;

			if (pdfDocument) {
				printPage(pdfDocument, documentName);
			}
		};
	}
}

function transformUnorderedLists(pdf) {
	let listItems = pdf.querySelectorAll("ul li");
	for (let li of listItems) {
		li.textContent = `O\u00A0\u00A0\u00A0${li.textContent}`;
	}
}

function getClosedAccordions(document) {
	let listOfAccordions = document.querySelectorAll("[data-html2canvas-expand]");

	let list = new Array();

	for (let i = 0; i < listOfAccordions.length; i++) {
		if (!listOfAccordions[i].classList.contains("open")) {
			list.push(listOfAccordions[i]);
		}
	}

	return list;
}

function openAccordionsOnPage(listOfAccordions) {

	listOfAccordions.forEach(element => element.classList.toggle("open"));
}

function printPage(document, documentName) {
	let pdf = new jsPDF('portrait', 'mm', 'a4');

	pdf.html(document, {
		callback: function (pdf) {
			pdf.save(documentName + '.pdf');
		},
		margin: 21,
		enableLinks: false,
		pagebreak: {
			mode: 'avoid-all'
		},
		image: { type: 'jpeg', quality: 1 },
		html2canvas: {
			allowTaint: true,
			dpi: 300,
			letterRendering: true,
			logging: true,
			scale: .2,
			scrollX: 0,
			scrollY: 0,
			useCors: true,
			onclone: async function (pdf) {

				let htmlDoc = pdf.querySelector('html');
				htmlDoc.style.letterSpacing = '.01px';

				let htmlBody = pdf.querySelector('body');
				htmlBody.style.fontFamily = 'sans-serif';

				transformUnorderedLists(pdf);

				let listOfClosedAccordions = getClosedAccordions(pdf);
				openAccordionsOnPage(listOfClosedAccordions);
			}
		},
		autoPaging: 'text',
	});
}
