﻿/* The script loader plugin executes the module in a global context, just as if you had included them via <script> tag.
// https://webpack.js.org/loaders/script-loader/. Use this only for legacy code */
//require("script-loader!gdpr-plugin");
import { GdprManager, GdprScreenManager } from "/App_plugins/PN-GDPR/scripts/MascGdpr.js"

const manager = GdprManager();
const screenManager = GdprScreenManager();

export function initializeGdpr() {
//Checks if the GDPR permission is set, if not, shows the screen for setting the permission
	if (manager.getCurrentPermission() === manager.permissionLevel.NotSet) {
		screenManager.loadScreen();
	}
}
