﻿import { vanillaSelectBox, getMultiSelectValues } from "../vendors/vanilla-select-box.js"
import { UpdateQueryParams, GetQueryString, RemoveQueryParam } from "../services/UrlService.js";

const REGIONSELECTID = "region-select";
const JOBFIELDID = "job-field-select";
const OVERVIEWPAGEID = "overviewPageId";
const JOBFIELD = "jobField";
const LOCATIONS = "locations";
const VACANCIESPAGELINKURL = getVacanciesPageLink();
const ALLREGIONSSTRING = "Alle plaatsen";
const REGIONSSTRING = "regio's";
const FILTERSFORMID = "filters-form";


export function VacanciesFilter() {

    let regionSelect = document.getElementById(REGIONSELECTID);

    if (regionSelect) {
        let overviewPageId = regionSelect.dataset.vacanciespageid;

        if (overviewPageId) {
            getVacanciesCount(overviewPageId);
        }

        let vacanciesFilter = new vanillaSelectBox('.multi-select',
            {
                placeHolder: ALLREGIONSSTRING,
                disableSelectAll: true,
                translations: {
                    "selectAll": ALLREGIONSSTRING,
                    "clearAll": ALLREGIONSSTRING,
                    "all": ALLREGIONSSTRING,
                    "items": REGIONSSTRING
                }
            });

        createClickableLabels(regionSelect, vacanciesFilter);


        let jobFieldSelectItems = document.querySelector(".select-items");
        if (jobFieldSelectItems) {

            if (overviewPageId) {
                jobFieldSelectItems.addEventListener("click", function (event) { onJobFieldSelect(event, overviewPageId) });
            }
            else {
                // if overviewPageId is not undefined; refresh page on click
                jobFieldSelectItems.addEventListener("click", function (event) { submitForm() });
            }
        }

        if (overviewPageId) {
            regionSelect.addEventListener("change", function (event) { onRegionSelect(overviewPageId) });
        }
    }
}

function createClickableLabels(regionSelect, vacanciesFilter) {
    let filterItems = vacanciesFilter.ul.children;

    for (let index = 0; index < filterItems.length; ++index) {
        let item = filterItems[index];

        item.querySelector("span").addEventListener('click',
            function () {
                item.classList.toggle('active');
                const option = regionSelect.querySelector(`option[value="${item.dataset.value}"]`);
                option.selected = !option.selected;
            });
    }
}

function submitForm() {
    let form = document.getElementById(FILTERSFORMID);
    if (form) {
        form.submit();
    }
}

function onJobFieldSelect(event, overviewPageId) {
    let jobField = event.target.dataset.value;
    let locations = getMultiSelectValues(REGIONSELECTID);

    getVacanciesCount(overviewPageId, jobField, locations);
}

function onRegionSelect(overviewPageId) {

    let jobField;
    let jobFieldSelect = document.getElementById(JOBFIELDID);

    if (jobFieldSelect) {
        jobField = jobFieldSelect.nextElementSibling.dataset.value;
    }

    let locations = getMultiSelectValues(REGIONSELECTID);

    getVacanciesCount(overviewPageId, jobField, locations)
}

function getVacanciesCount(overviewPageid, jobField, locations) {

    let requestUrl = "/umbraco/surface/vacancies/getvacanciescount";

    requestUrl = UpdateQueryParams(requestUrl, OVERVIEWPAGEID, overviewPageid);

    if (jobField) {
        requestUrl = UpdateQueryParams(requestUrl, JOBFIELD, jobField);
    }

    if (locations && locations.length > 0) {
        requestUrl = UpdateQueryParams(requestUrl, LOCATIONS, locations);
    }

    loadVacanciesCount(requestUrl);
}

function loadVacanciesCount(requestUrl) {
    var xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            if (xmlhttp.status == 200) {

                setVacanciesPageLink(requestUrl);
                setVacanciesCountLabel(xmlhttp.responseText);
            }
            else if (xmlhttp.status == 400) {
                console.error('There was an error 400');
            }
            else {
                console.error('Something else other than 200 was returned');
            }
        }
    };

    xmlhttp.open("GET", requestUrl, true);
    xmlhttp.send();
}

function setVacanciesCountLabel(count) {

    let label = document.getElementById("vacancies-count-label");
    label.innerHTML = `Toon <strong>${count}</strong> vacatures`;

    if (count == 1) {
        label.innerHTML = label.innerHTML.substring(0, label.innerHTML.length - 1);
    }
}

function getVacanciesPageLink() {
    let link = document.getElementById('vacancies-page-link');

    if (link) {
        return link.href;
    }
}

function setVacanciesPageLink(requestUrl) {

    let queryString = GetQueryString(requestUrl);
    queryString = RemoveQueryParam(queryString, OVERVIEWPAGEID)

    let vacanciesPageLink = document.getElementById('vacancies-page-link');
    vacanciesPageLink.href = VACANCIESPAGELINKURL + queryString;
}

let filterButton = document.getElementById("active-filters-title-mobile");
let filterButtonContent = document.getElementById("active-filters-title-mobile-content");
let selectedLocations = document.querySelector(".selected-locations");

// Vacancies active filters
if (filterButton && filterButtonContent) {
    filterButton.addEventListener("click", function () {
        if (selectedLocations.classList.contains("d-none")) {
            selectedLocations.classList.remove("d-none");
            filterButton.classList.add("active");
            filterButtonContent.textContent = "Verberg filters";
        } else {
            selectedLocations.classList.add("d-none");
            filterButton.classList.remove("active");
            filterButtonContent.textContent = "Toon filters";
        }
    });
}

function removeLocation(location) {
    let selectElement = document.querySelector('#region-select');
    for (var i = 0; i < selectElement.options.length; i++) {
        if (selectElement.options[i].value === location) {
            selectElement.options[i].selected = false;
            break;
        }
    }

    let selectedLocation = document.querySelector(`#selected-locations li[data-location='${location}']`);
    if (selectedLocation) {
        selectedLocation.remove();
    }

    document.getElementById("region-select").form.submit();
}

document.querySelectorAll(".remove-location").forEach(button => {
    button.addEventListener("click", function () {
        removeLocation(this.getAttribute("data-location"));
    });
});

function removeAllLocations() {
    let selectElement = document.querySelector('#region-select');
    for (var i = 0; i < selectElement.options.length; i++) {
        selectElement.options[i].selected = false;
    }

    let selectedLocations = document.querySelectorAll("#selected-locations li");
    selectedLocations.forEach(location => {
        location.remove();
    });

    document.getElementById("region-select").form.submit();
}

let removeButton = document.querySelector("#remove-all-locations");

if (removeButton) {
    removeButton.addEventListener("click", removeAllLocations);
}