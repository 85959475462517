﻿import { GdprManager } from "/App_plugins/PN-GDPR/scripts/MascGdpr.js"

const manager = GdprManager();
const path = '/' + window.location.pathname.split('/')[1];
const cookieName = "RivasPopupClosed";

export function Popup() {
	let popupMessageElement = document.getElementById("message-popup");
	if (!popupMessageElement) return; 

	let popupToggle = document.getElementById("toggle-popup");
	if (!popupToggle) return; 

	initializePopup(popupToggle);

	let closeButton = popupMessageElement.querySelector("#close-popup-banner");
	if (!closeButton) return; 

	closeButton.addEventListener('click', event => {
		if (manager.getCurrentPermission() !== manager.permissionLevel.NotSet) {
			document.cookie = cookieName +"=1," + path + ";path=" + path;
		}
	})
}

function initializePopup(popupToggle) {
	var [cookieValue, cookiePath] = getCookieValue(cookieName)?.split(',') ?? [null, ''];
	var popupClosed = cookieValue === "1";

	if (popupClosed && cookiePath === path && IsLowerOrEqualLevelPath(cookiePath, path)) {
		popupToggle.checked = true;
	}
}

var getCookieValue = function (name) {
	var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
	return !!pair ? pair[1] : null;
};

function IsLowerOrEqualLevelPath(pathToTest, currentPath) {
	if (!pathToTest || !currentPath) return false;
	return pathToTest.split('/').length >= currentPath.split('/').length;
}